.background-image {
  background: url("../../images/interview-confirmation-bg-image.jpg");
  background-size: cover;
  height: 100vh;

  button {
    margin-bottom: 0;
  }
}

.logo {
  width: 12.5rem;
}

.white-text {
  color: $white;
}

.left-arrow-icon{
  color: $cytonn-green;
  font-weight: 900;
  font-size: 1rem;
}

.round-edges{
  width: 60px;
  border-radius: 20px !important;
}

/*
*   MEDIA QUERIES
*/

// Styles on screen widths larger than 640px
@media only screen and (min-width: 640px){
  .padding-all{
    padding: 3rem 2rem 1rem 2rem;
  }

  .text-content {
    padding: 10rem 0;
  }

  .body-height {
    height: 600px;
  }
}

// Styles on screen widths below 640px
@media only screen and (max-width: 640px){
  .text-content{
    padding: 5rem 0;
  }

  .body-height {
    height: 300px;
  }

}

