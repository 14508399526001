.landing .row {
  max-width: 68rem;
}

body {

  .landing-header {
    .callout {
      position: relative;
      padding: 0 1rem 2.1rem;
      background-image: url('/images/banner.jpg');
      background-size: cover;
      border: none;

      .row {
        max-width: 78rem;
      }

      .top-bar {
        background: none;

        hr {
          border-bottom: 1px solid #fbfbfb;
          margin-left: 16px;
          margin-right: 16px;
          margin-top: 13px;
          opacity: 0.2;
        }

        ul li {
          background: none;
          border-right: none;
          font-size: 13px;
          .first-link {
            padding-right: 0px;
          }
          i {
            padding-bottom: 3px;
          }
        }

        .top-bar-left .menu, .top-bar-right .menu {
          background: none;
        }

        .top-bar-right ul {
          margin: .8125rem 0 !important;
        }

        .menu-logo a img {
          width: 139px;
          height: 40px;
        }
      }

      .text-center {
        text-transform: uppercase;
        color: #fff;

        h1 {
          font-family: 'OpenSans-Bold', sans-serif;
          letter-spacing: 16px;
          font-size: 3.5em;
        }
        p {
          font-size: 12px;
          letter-spacing: 9px;
        }
        hr {
          max-width: 9rem;
          border-bottom: 1px solid #fbfbfb;
          opacity: 0.3;
        }
      }

      .back-link {
        position: absolute;
        right: 0;
        bottom: 0;
        padding: 10px 15px 10px 15px;
        background-color: $cytonn-light-green;
        font-size: 13px;
        font-weight: 700;

        a, i {
          color: #fff;
        }
        i {
          font-size: 15px;
          font-weight: 700;
        }
      }
    }
  }

  .landing-content {
    margin-top: 46px;

    hr {
      margin-bottom: 35px;
      border-bottom: 1px solid #eee;
      margin-left: 11px;
      margin-right: 13px;
    }

    .text-uppercase {
      text-transform: uppercase;
    }

    .landing-content-left {
      border-right: 1px solid #eee;

      .icon-box {
        width: 95px;
        height: 80px;
        text-align: center;
        font-size: 38px;
        color: white;
        margin-bottom: 20px;
        padding-top: 12px;
      }
      .briefcase {
        background-color: #066b60;
      }
      .trophy {
        background-color: $cytonn-light-green;
      }
      p {
        font-size: 12px;
        margin-bottom: 1px;
      }
    }

    .landing-content-center {
      h3 {
        line-height: 0.8;
        margin-bottom: 30px;
        margin-top: -7px;
        a, a:hover {
          font-size: 20px;
          color: black;
          font-weight: 700;
          font-family: 'Open Sans';
        }
      }
      p a, p a:hover {
        font-size: 13px;
        color: black;
        line-height: 1.5;
      }
      .read-more {
        a {
          font-size: 13px;
          color: $cytonn-light-green;
        }
        a:hover {
          color: #066b60;
          font-size: 12px;
        }
      }
    }

    .landing-content-right {
      .job-type {
        margin-top: 33%;
        a {
          border: 2px solid #5e5e5e;
          border-radius: 5px;
          padding: 5px 20px 5px 20px;
          font-weight: 700;
          color: #7d7d7b;
          font-size: 14px;
          float: right;
        }
        a:hover {
          color: #fff;
          background-color: $cytonn-light-green;
          border: 2px solid $cytonn-light-green;
        }
      }
    }

    .landing-content-pagination {

    }
  }


  a {
    color: $cytonn-light-green;
  }
  a:hover {
    color: $cytonn-green;
  }

  .gradient {
    width: 100%;
    background: linear-gradient(-90deg, #8cc16e, #056a5f);
    height: 6px;
    margin-top: 20px;
  }

  .footer {
    background: #f7f9f9;
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    text-align: center;
  }
}

/*
* Small screens styling
*/
@media screen and (max-width: 39.9375em) {
  body {
    .landing-header {
      .callout {
        padding: 0 3rem 3rem 1rem;
        .top-bar {
          hr {
            margin-right: 64px;
          }

          .top-bar-right ul {
            margin: 0 !important;
            i{
              display: none;
            }
          }
        }
        .text-center {
          text-transform: uppercase;
          color: #fff;
          h1 {
            font-size: 1.5em;
          }
          p {
            font-size: 8px;
          }
        }
      }
    }

    .landing-content {
      padding-left: 20px;
      padding-right: 20px;
      margin-top: 18px;

      .landing-content-right {
        margin-bottom: 10px !important;
        .job-type {
          margin-top: 0%;
          a {
            float: left;
          }
        }
      }

      .landing-content-center {
        h3 {
          line-height: 1.6;
          margin-bottom: 8px;
        }
      }

      .landing-content-left {
        border-right: none;
      }
    }
  }
}

/*
* Medium screens styling
*/
@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  body {
    .landing-content {
      padding-left: 20px;
      padding-right: 20px;

      .landing-content-right {
        margin-bottom: 10px !important;
        .job-type {
          margin-top: 0%;
          a {
            float: left;
          }
        }
      }

      .landing-content-center {
        h3 {
          line-height: 1.6;
          margin-bottom: 8px;
        }
      }

      .landing-content-left {
        border-right: none;
      }
    }
  }
}



