@import "partials/settings";
@import "landing.scss";
@import "job.scss";
@import "main_layout.scss";
@import "login_modal.scss";
@import "application_modal.scss";
@import "dashboard.scss";
@import "signup.scss";
@import "profile.scss";
@import "search.scss";
@import "referral.scss";
@import "aptitude.scss";
@import "responsive.scss";
@import "interview_confirmation.scss";
@import "bower_components/foundation-sites/scss/foundation.scss";

@include foundation-everything;

body{

  .error{
    color: red;
  }

  $menu-background: #333;

  .menu > li > a img {
    margin-right: 0.25rem;
    display: inline-block;
    vertical-align: middle;
  }

  .profile-circle {
    width: 40px;
    height: 40px;
    border-radius: 250px;
  }

  .title-bar {
    background: $menu-background;
  }

  .top-bar {
    background: $menu-background;

    ul {
      background: $menu-background;

      li {
        background: $menu-background;

        a {

          color: #fff;
        }
      }
    }
  }

  .button{
    background-color: #8cc16e;
  }

  .hollow-button {
    transition: background-color .9s ease;
    border: 1px solid #8cc16e;
    border-radius: 25px;
    padding: 7px 27px 7px 27px;
    color: #8cc16e;
    font-weight: 500;
    letter-spacing: 1px;
    font-size: 12px;
    line-height: 16px;
    background-color: #fff;
    text-transform: uppercase;
  }

  .hollow-button:hover, .hollow-button:focus {
    background-color: #8cc16e;
    color: #fefefe;
  }

  .menu-text {
    color: #fff;

    @media only screen and (max-width: 40em) {
      display: none !important;
    }
  }

  @media only screen and (min-width: 40em) {
    .menu:last-child {
      border-left: 1px solid #4e4e4e;
    }

    .menu:first-child {
      border-left: none;
    }

    .menu {
      li:not(:last-child) {
        border-right: 1px solid #4e4e4e;
      }
    }
  }

  .dropdown.menu .submenu {
    border: none;
  }

  .dropdown.menu .is-dropdown-submenu-parent.is-right-arrow>a::after {
    border-color: #fff transparent transparent;
  }

  .is-drilldown-submenu-parent>a::after {
    border-color: transparent transparent transparent #fff;
  }

  .js-drilldown-back::before {
    border-color: transparent #fff transparent transparent;
  }

  .text-align-left{
    text-align: right !important;
  }


}