body {

  .application-modal-title {
    font-family: "Open Sans", sans-serif;
    font-size: 25px;
    font-weight: 300;
    text-transform: uppercase;
  }

  .application-modal-job-title {
    font-weight: 600;
    margin-top: -13px;
  }

  .application-modal-content {
    font-family: "Open Sans", 'FontAwesome', sans-serif;
    //width: 45%;
    //padding-left: 5%;
    //padding-right: 5%;
    border-color: $cytonn-light-green;
    select:focus {
      outline-color: $cytonn-light-green;

    }
    button:hover {
      background: $cytonn-green;
    }
  }

  .apply-button {
    width: 20%;
    border-radius: 5%;
    background: #535d66 !important;
  }

  .cancel-button {
    width: 20%;
    margin-right: 15px;
    border-radius: 5%;
    background: $cytonn-light-green;
  }
}
