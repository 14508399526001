body {

  .profile-title h5 {
    color: #2b3a40;
    padding-bottom: 3px;
    padding-top: 20px;
    font-size: 18px;
    font-weight: 600;
  }

  .profile-item-title {
    color: #2b3a40;
    font-size: 16px;
    font-weight: 600;
  }

  .profile-image-section {
    margin-bottom: 15px;
    .profile-image {
      max-height: 200px;
      overflow: hidden;
      margin-bottom: 5px;
    }

  }

  .profile-tabs-section {

    .profile-tabs-header {
      background-color: white;
      padding: 25px 20px 15px 20px;

      .tabs {
        background: none;
        border: none;
        border-bottom: 1px solid #e3e9ee;
        padding-bottom: 12px;

        li a {
          color: #2b3a40;
          font-weight: 700;

          i {
            padding-right: 6px;
          }
        }

        .tabs-title > a {
          padding: 10px 18px 10px 18px;
        }

        .tabs-title > a:focus, .tabs-title > a[aria-selected='true'] {
          background: #2b3a40;
          border-radius: 15px;
          color: white;
        }

      }
    }

    .profile-tabs-body {

      table {
        width: 80%;
        margin-left: -9px;
      }

      table thead, table tbody, table tfoot {
        border: none;
      }

      table tbody tr:nth-child(even) {
        background-color: white;
      }

      table tbody th, table tbody td {
        padding: 0rem 0.625rem 0.3rem;
      }

      .personalInfo, .experienceTab, .educationTab, .referenceTab, .relationshipTab {
        background-color: white;
        margin-bottom: 30px;
      }

      .profile-text {
        color: #3374b7;
      }

      .add-new-section {
        background-color: white;
        margin-bottom: 20px;
        padding-bottom: 20px;
        a {
          color: #2b3a40;
          font-size: 12px;
        }
      }

      .blank-space {
        height: 20px;
        background-color: #f3f3f3;
        margin-left: -16px;
        margin-right: -16px;
      }

      .profile-item-body {
        padding: 20px 20px 0px 20px;
      }

      .profile-item-title {
      }

      .profile-item-action-section {
        padding-bottom: 35px;
        a {
          color: #2b3a40;
          font-size: 12px;
        }
      }

      .profile-edit-button-section {
        padding-bottom: 40px;
        border-bottom: 1px solid #e3e9ee;

        a {
          color: #2b3a40;
          font-size: 12px;
        }
      }

      .profile-cv-section {
        h4 {
          margin-bottom: -25px;
          margin-top: 15px;
          font-weight: 200;
        }

        h3 {
          font-size: 20px;
          font-weight: 500;
          margin-top: 8px;
        }
      }

      .profile-edit-section{
        padding-left: 20px;
      }

    }
  }
}

/*
* Medium screens styling
*/
@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  body {
    .profile-tabs-section {
      .profile-tabs-body {
        table {
          width: 100%;
        }
      }
    }
  }
}

/*
* Small screens styling
*/
@media screen and (max-width: 39.9375em) {
  body {
    .profile-tabs-section {
      .profile-tabs-body {
        table {
          width: 100%;
        }
      }
    }
  }
}