body {
  .login-form {
    padding-top: 2%;
    padding-left: 0 !important;
  }
  .title {
    font-size: 100%;
    padding-left: 5%;

  }
  .form-input {
    box-shadow: none !important;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-bottom: 1px solid #c4c4c4 !important;
    display: block;
    width: 100%;
    padding: 0;
    text-align: left;
    white-space: pre;

  }
  .fontAwesome {
    font-family: "Open Sans", 'FontAwesome', sans-serif;
  }
  .login-button {
    border-radius: 5%;
    // width: 50%;
    background: $cytonn-light-green;

  }
  .modal-submit {
    padding-top: 10%;
    padding-bottom: 10%;
  }
  @media only screen and (max-width: 600px) {
    .medium {
      width: 100% !important;
      border-color: #fff;
      border: 0 !important;
      height: 362px !important;
      padding: 0 !important;
      margin: 0 !important;

      .login-form {
        margin-top: 230px;
        padding-bottom: 30px;
      }

      .modal-image {
        position: absolute;
        margin: 0;
        padding: 0;
        min-height: 100px;
        z-index: -10;
        width: 100% !important;

        .modal-close {
          display: block !important;
          margin-right: 20px;
        }
        .modal-text {
          padding: 0 !important;
          margin: 0 !important;
          display: block !important;
          font-size: 8vw;
        }

        img {
          position: absolute;
          width: 100%;
          min-height: 200px !important;
          display: flex !important;
        }
      }
    }
  }

  @media only screen and (min-width: 601px) {
    .medium {
      width: 50% !important;
      border-color: #fff;
      border: 0 !important;
      height: 362px !important;
      padding-bottom: 0 !important;

      img {
        position: absolute;
        display: flex;
        margin-top: -19%;
        //margin-left: -3% !important;
        height: 117.5%;
        width: 56.3% !important;
        z-index: -1;

      }
    }
  }
  .modal-text {
    font-family: "Open Sans", sans-serif;
    z-index: 1;
    padding-top: 2% !important;
    font-size: 25px !important;
    margin-top: 10%;
    color: #fff;
    text-align: center;
  }
  .modal-weighted-text {
    font-family: "OpenSans-Bold", sans-serif;
    color: $cytonn-light-green;
  }
  .modal-close {
    z-index: 1;
    color: #c4c4c4 !important;
    padding-right: -10px !important;
  }

  .modal-links {
    font-family: "Open Sans", sans-serif;
    font-size: 15px;
  }
  .register {
    margin-right: -20%;
  }
}

@media only screen and (max-width: 1020px) {
  .medium {
    width: 100% !important;
    border-color: #fff;
    border: 0 !important;
    height: 100%;
    padding-bottom: 0 !important;
    img {
      display: none !important;

    }
  }
  .modal-close {
    display: none !important;
  }
  .modal-text {
    display: none !important;
  }
  .modal-links {
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
  }
}

@media only screen and (max-width: 1440px) {

  .modal-text {
    font-size: 18px !important;
    padding-top: 5% !important;
  }
  .modal-links {
    font-family: "Open Sans", sans-serif;
    font-size: 10px;
  }
  .title {
    text-align: left !important;
  }
  .form-input {
    text-align: justify !important;
  }
  .medium {
    width: 49% !important;
    img {
      position: absolute;
      width: 60%;
      display: flex;
      margin-top: -19%;
      //margin-left: -3% !important;
      height: 112.2% !important;
      z-index: -1;
    }
  }

}