body {
  .aptitude {
    margin-top: 20px;

    .page-body, .page-header {
      background-color: white;
    }

    table thead td {
      font-size: 14px;
      font-weight: 600;
    }

    .page-header {
      padding: 20px 20px 10px 20px;
      p {
        font-size: 14px;
      }
      td {
        font-size: 14px !important;
        font-weight: 600 !important;
      }
    }

    .page-body {
      padding: 0px 20px 20px 20px;

      table {
        .table-header {
          background-color: #d5f1fc;
        }
      }
    }

    .page-title {
      font-weight: 700;
      color: #2b3a40;
      font-size: 20px;
    }

    .action-section {
      a {
        color: #2b3a40;
        font-size: 13px;
        margin-top: 4px;
        font-weight: 700;
      }
    }
  }
  .min-button-width {
    min-width: 6rem;
  }
}