body {
  .signup-menu {
    background-color: #f3f6f8;
    border-bottom: 1px solid #eceeee;
    color: #c6cbcf;

    .row {
      margin-top: -16px;
      padding-left: 9px;
      padding-right: 10px;
    }

    .signup-menu-item {
      border-left: 1px solid #eceeee;
      font-size: 12px;
      font-weight: 500;
      padding: 10px 20px 10px 50px;
    }

    .last-item {
      border-right: 1px solid #eceeee;
    }

    .active-signup-menu-item {
      background-color: #8cc16e;
      color: white;
    }

    .complete-signup-menu-item {
      background-color: white;
      color: #98a1a7;
      i {
        color: #8cc16e;
      }
    }

  }

  .signup-header {
    margin-top: 30px;
    margin-bottom: 40px;
    border-bottom: 1px solid #c3c4c5;
    text-align: center;

    .signup-header-title {
      font-size: 24px;
      color: #8cc16e;
    }

    .signup-header-description {
      font-size: 15px;
      color: #c3c4c5;
    }
  }

  .signup-form{
    input, select {
      margin-bottom: 0 ! important;
    }
    .column{
      margin-bottom: 1rem !important;
    }
    .error{
      display: block;
      margin-top: 10px;
    }
  }
  input.ng-not-empty.ng-valid-required.ng-touched.ng-dirty.ng-valid-parse.ng-invalid.ng-invalid-ng-intl-tel-input {
    color: red;
    border-color: red;
  }

  .relationship-details-callout {
    display: inline-block;
    border-radius: 3px;
    padding: 1%;
    background-color: grey;
    //background-color: #176A5D;
    color: #ffffff;
    margin-right: 5px;
    margin-bottom: 5px;
  }

  .candidate-relationship {
    width: auto;
    padding: 1px;
    margin-left: 10px;
  }

  .relationship-details-close-callout {
    float: right;
    cursor: pointer;
  }
  }

  /*
  * Small screens styling
  */
  @media screen and (max-width: 39.9375em) {
    body {
      .signup-menu {
        .row {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }

  /*
  * Medium screens styling
  */
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    body {
      .signup-menu {
        .row {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }

  .intl-tel-input {
    display: block;
  }

  .intl-tel-input .country-list {
    position: unset !important;
    white-space: unset !important;
    padding: 0;
  }

  .has-error .form-control {
    border-color: #a94442;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  }