body {
  .referral {
    margin-bottom: 40px;

    .photo-section {
      text-transform: uppercase;
      color: #8cc16e;
      .intro {
        margin-bottom: 0px;
        letter-spacing: 3px;
        font-size: 29px;
        font-weight: 100;
        font-family: sans-serif;
      }
      .about {
        font-size: 24px;
        font-family: inherit;
        font-weight: bolder;
        line-height: 8px;
      }
    }

    .form-section {
      padding: 25px;
      box-shadow: 0px 0px 10px #d1d1d1;

      h4 {
        font-size: 24px;
        color: #056a5f;
      }
    }
  }
}

/*
* Small screens styling
*/
@media screen and (max-width: 39.9375em) {
  body {
    .referral {
      .photo-section {
        .intro {
          margin-bottom: 0px;
          letter-spacing: 3px;
          font-size: 24px;
        }
        .about {
          font-size: 20px;
          font-weight: 700;
        }
      }
    }
  }
}