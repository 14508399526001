body {
  font-family: 'Open Sans', sans-serif;
  .off-canvas {
    background: #2b3a40;
    height: 100%;
    width: 16%;
  }

  .dashboard-title-menu {
    .top-bar {
      background: $white;
      //padding-top: 19px;
      padding-bottom: 5px;
    }

    .top-bar .top-bar-left ul li {
      padding-top: 25px;
    }

    .top-bar ul li a {
      background: $white;
      color: black;
      border-right: none;
    }
    .top-bar ul li {
      background: $white;
      color: black;
      border-right: none;
    }

    .top-bar-right {
      .menu > li > a {
        padding: 0.7rem 0.3rem;
      }

      ul li ul li a{
       border: 1px solid #7b8da0;
      }
    }
  }

  .dashboard-left-side {
    min-height: 100vh;
    position: relative;
    background: #f3f3f3;
    padding-bottom: 40px;
  }

  .dashboard-content {

  }

  .dashboard-menu {
    padding-left: 5%;
    padding-top: 3%;
  }
  .menu > li > a {
    color: $white;
    font-family: 'Open Sans', sans-serif;
  }
  .dashboard-menu:hover {
    background: $cytonn-green;
  }
  .active-dashboard-link {
    background: #222d32;
    border-left: 4px solid #a3cf5f;
  }
}

.logout {
  border-right: 4px dotted #000 !important;
  margin-right: 50px !important;
}

.logout-link {
  display: none;
}

.logout:hover .logout-link {
  display: block;
}

.dashboard-image {
  display: block;
  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }
  background: #222d32;
  padding-top: 19px;
  padding-bottom: 19px;
}

.job-page-header {
  border-bottom: 2px solid #c8c9ca;
  margin-bottom: 20px;
  padding-bottom: 10px;
  margin-top: 30px;

  h5, p {
    color: #7b8da0;
  }
}

.job-item {
  background-color: #fff;
  padding: 10px;
  margin-bottom: 20px;

  .job-item-left {
    i {
      font-size: 60px;
      color: #0099cc;
    }
  }

  .active-job .job-item-left i {
    color: #ff9900;
  }

  .pending-job .job-item-left i {
    color: #ff6666;
  }

  .job-item-center {
    h5 a {
      color: #2b3a40;
    }
    p {
      margin-bottom: 0px;
    }
  }

  .job-item-right {
    .button {
      color: #fff;
      background-color: #2b3a40;
      padding: 7px;
      margin-bottom: -56px;
    }
  }
}

.jobs-dashboard {
  margin-left: 15px;
  margin-right: 6px;
}

.second-row{
padding-top: 20px;
}

.jobs-dashboard-item {
  margin-bottom: 20px;
  margin-right: 10px;

  .active-jobs-left, .pending-jobs-left, .applied-jobs-left, .active-internal-jobs-left {
    text-align: center;
    i {
      font-size: 40px;
      margin-top: 28px;
      color: #fff;
    }
  }

  .active-jobs-left {
    background-color: #ff9900;
  }

  .pending-jobs-left {
    background-color: #ff6666;
  }

  .applied-jobs-left {
    background-color: #0099cc;
  }
  .active-internal-jobs-left{
    background-color:$cytonn-green;
  }

  .active-jobs-right, .pending-jobs-right, .applied-jobs-right, .active-internal-jobs-right {
    background-color: #fff;
  }

  .active-jobs-left, .pending-jobs-left, .applied-jobs-left, .active-jobs-right, .active-internal-jobs-right,
  .active-internal-jobs-left,.pending-jobs-right, .applied-jobs-right {
    height: 100px;
  }

  .jobs-section-title {
    margin-bottom: 2px;
    margin-top: 10px;

    a {
      color: #818080;
      font-size: 13px;
    }
  }

  .jobs-count {
    font-size: 50px;
    .active a {
      color: #ff9900;
    }
    .pending a {
      color: #ff6666;
    }
    .applied a {
      color: #0099cc;
    }
  }

  .jobs-icon {
    font-size: 19px;
    padding-top: 40px;
  }
}

.dashboard-footer {
  position: absolute;
  bottom: 0;

  .dashboard-footer-body {
    border-top: 2px solid #c8c9ca;

    p {
      padding-top: 10px;
      color: #7b8da0;
      font-size: 12px;
    }
  }
}

.job-details {

  .job-details-header {
    background-color: #fff;
    padding-left: 10px;
    margin-bottom: 20px;

    .job-details-header-icon {
      font-size: 60px;
    }
    .job-details-header-data {
      h5 {
        margin-top: 20px;
      }
      p {
        margin-bottom: 0px
      }
    }
  }

  .job-details-body {
    background-color: #fff;
    padding-top: 20px;
    margin-bottom: 30px;

    .job-details-body-header {
      margin-bottom: 20px;
      padding: 0 0.5rem;
      p {
        margin: 0px;
      }
    }

    .button {
      padding: 6px 17px 7px 17px;
    }
  }

  .active-job {
    i {
      color: #ff9900;
    }
    .button {
      background-color: #ff9900;
    }
  }
  .pending-job {
    i {
      color: #ff6666;
    }
    .button {
      background-color: #ff6666;
    }
  }
  .applied-job {
    i {
      color: #0099cc;
    }
    .button {
      background-color: #0099cc;
    }

    .job-details-header .job-details-header-data {
      padding-bottom: 20px;
    }
  }
}

/*
* Medium screens styling
*/
@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .jobs-dashboard-item {
    .jobs-icon {
      padding-top: 40px;
      padding-left: 25px;
    }
  }
}

/*
* Small screens styling
*/
@media screen and (max-width: 39.9375em) {
  .job-item {
    .job-item-right {
      .button {
        margin-bottom: 10px;
      }
    }
  }
}


