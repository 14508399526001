body {
  .job {
    padding-bottom: 5%;
  }

  .job p, .job p span, .job ol li, .job-details-body-data p, .job-details-body-data p span, .job-details-body-data ol li {
    font-family: 'Open Sans', sans-serif !important;
    color: black !important;
    font-size: 14px !important;
  }
  .job-details-body-data{
    padding: 0 0.5rem;
  }
  .back-to-jobs {
    font-size: 14px;
  }

  .job-title {
    font-family: 'Open Sans', sans-serif;
    padding-top: 23px;
    font-weight: 200;
    padding-bottom: 0;
    font-size: 24px;
  }

  .text-color {
    color: #8cc16e;
  }

  .job-sub-title {

    dl {
      font-size: 14px;
      dt {
        float: left;
        font-weight: bold;
        margin-right: 10px;
        width: 100px;
      }
      dd {
        margin: 4.8px 0;
      }
    }
  }

  .job-sub-type {
    font-size: 17px !important;
  }

  .main-dl {
    margin-bottom: 23px;
    margin-top: 30px;
  }

  .jobs-dl{
    dl{
      font-size: 12px !important;
    }
  }

  .job-details {
    font-family: 'Open Sans', sans-serif;
    font-weight: 200;
  }
  .underline {
    text-transform: uppercase;
    margin-bottom: 23px;
    margin-top: 30px;
    font-size: 17px;
    font-weight: 900;
  }
  .application-sidebar h5 {
    color: $cytonn-light-green;
  }

  .button-login {
    text-transform: uppercase;
  }

  .button-login:hover {
    background-color: #066b60;
  }
  .application-sidebar-shaded {
    background: #f7f9f9;
  }
  .application-links {
    padding-top: 6%;
    padding-bottom: 4%;
    p {
      font-size: 12px !important;
    }
    i {
      font-size: 16px !important;
    }
  }
  .background-strike-through {
    text-align: center;
    color: $border-color;
    display: table;
    white-space: nowrap;
    &:before, &:after {
      border-top: 1px solid $border-color;
      content: '';
      display: table-cell;
      position: relative;
      top: 0.8em;
      width: 50%;
    }
    &:before {
      right: 1.5%;
    }
    &:after {
      left: 1.5%;
    }
  }

  .apply-job h5 {
    font-size: 18px;
    font-weight: 700;
  }

  .share-job h5 {
    padding-top: 10%;
    font-family: 'Open Sans', sans-serif;
    color: black;
    font-size: 18px;
  }

  h5, p {
    font-family: 'Open Sans', sans-serif;
  }
  .right-border {
    padding-right: 7%;
    border-right: solid $border-color 1px;
  }
  .linkedIn {
    color: $linkedIn-color !important;
  }
  .twitter {
    color: $twitter-color !important;
  }
  .facebook {
    color: $facebook-color !important;
  }
}

